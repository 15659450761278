<template>
  <div class="vehicle-info-content" ref="vehicleInfo">
    <div>
      <div>
        <custom-dialog
          :isDialogOpen="isDialogOpen"
          @close="closeDialog"
          @save="saveOrUpdateTempData"
        />
        <rego-popup
          :isShowStateField="!isNZ"
          :isShowRegoPopup="isShowRegoPopup"
          :isLoadingApi="isLoadingApi"
          @skipRego="skipRego"
          @updateRego="updateRego"
        />
        <ListedCarMessage />
        <b-card class="px-2 py-4 rounded-2 mb-4">
          <div class="center mb-4 base-width">
            <h1
             class="font-weight-bold fs-20 mb-4 content-title"
            >
              CAR INFORMATION
            </h1>
            <b-row>
              <b-col v-if="!isNZ" cols="12" md="6" class="px-3">
                <custom-select
                  label="State"
                  v-model="info.location"
                  :options="states"
                  :error="$v.info.location.$error"
                  helperText="Please select state"
                  name="state"
                  :isFocus="curStepInput === 1"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col cols="12" md="6" class="px-3">
                <custom-autocomplete-input
                  label="Make"
                  v-model="info.make"
                  :options="makes"
                  :error="$v.info.make.$error"
                  helperText="Please enter make"
                  name="make"
                  :isFocus="curStepInput === 2"
                  @setNextInput="setNextInputNumber"
                  placeholder="Enter make"
                  :isLoadAble="true"
                  :clearable="true"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col cols="12" md="6" class="px-3">
                <custom-clear-select
                  id="model"
                  label="Model"
                  v-model="info.model"
                  :isClear="info.model !== '' && models.length > 1"
                  :options="models"
                  :error="$v.info.model.$error"
                  helperText="Please select model"
                  name="model"
                  :isFocus="curStepInput === 3"
                  :isLoadAble="true"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col cols="6" :class="isMobile ? 'pl-3 pr-2' : 'px-3'">
                <custom-select
                  label="Colour"
                  v-model="info.color"
                  :options="colors"
                  :error="$v.info.color.$error"
                  helperText="Please select colour"
                  name="color"
                  :isFocus="curStepInput === 4"
                  :isLoadAble="true"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col cols="6" :class="isMobile ? 'pl-2 pr-3' : 'px-3'">
                <custom-clear-select
                  id="year"
                  label="Year"
                  v-model="info.year"
                  :isClear="info.year !== ''"
                  :options="years"
                  helperText="Please select year"
                  :error="$v.info.year.$error"
                  name="year"
                  :isFocus="curStepInput === 5"
                  :isLoadAble="true"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col cols="12" md="6" class="px-3">
                <custom-clear-select
                  id="variant"
                  :label="isNZ ? 'Sub model' : 'Variant'"
                  name="badgeEdition"
                  v-model="info.badgeEdition"
                  :options="badgeEditions"
                  :isFocus="curStepInput === 10"
                  :isClear="this.isClearOptions.isClearBadgeEdition"
                  :error="$v.info.badgeEdition.$error"
                  :helperText="`Please select ${isNZ ? 'sub model' : 'variant'}`"
                  :isLoadAble="true"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                  :optional="isNZ"
                />
              </b-col>
              <b-col cols="12" md="6" class="px-3">
                <custom-clear-select
                  id="body-type"
                  label="Body type"
                  name="bodyType"
                  v-model="info.bodyType"
                  :options="bodyTypes"
                  :isFocus="curStepInput === 6"
                  :isClear="this.isClearOptions.isClearBody"
                  :error="$v.info.bodyType.$error"
                  helperText="Please select body type"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col cols="6" :class="isMobile ? 'pl-3 pr-2' : 'px-3'">
                <custom-clear-select
                  id="fuel-type"
                  label="Fuel type"
                  name="fuelType"
                  v-model="info.fuelType"
                  :options="fuelTypes"
                  :isFocus="curStepInput === 7"
                  :isClear="this.isClearOptions.isClearFuel"
                  :error="$v.info.fuelType.$error"
                  helperText="Please select fuel type"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col cols="6" :class="isMobile ? 'pl-2 pr-3' : 'px-3'">
                <custom-clear-select
                  id="drive"
                  label="Drive type"
                  name="drive"
                  v-model="info.drive"
                  :options="drives"
                  :error="$v.info.drive.$error"
                  :isClear="this.isClearOptions.isClearDrive"
                  helperText="Please select drive type"
                  :isFocus="curStepInput === 8"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col cols="12" md="6" class="px-3">
                <custom-clear-select
                  id="transmission"
                  label="Transmission"
                  name="transmission"
                  :options="transmissions"
                  v-model="info.transmission"
                  :error="$v.info.transmission.$error"
                  :isClear="this.isClearOptions.isClearTrans"
                  helperText="Please select transmission"
                  :isFocus="curStepInput === 9"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col v-if="!isNZ" cols="6" :class="isMobile ? 'pl-3 pr-2' : 'px-3'">
                <custom-clear-select
                  id="series"
                  label="Series"
                  name="series"
                  v-model="info.series"
                  :options="series"
                  :isFocus="curStepInput === 11"
                  :error="$v.info.series.$error"
                  :isClear="this.isClearOptions.isClearSeries"
                  helperText="Please select series"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col
                :cols="isNZ ? 12 : 6"
                :md="6"
                :class="isMobile && !isNZ ? 'pl-2 pr-3' : 'px-3'"
              >
                <custom-clear-select
                  label="Model year"
                  v-model="info.model_year"
                  :options="modelYears"
                  :isFocus="curStepInput === 12"
                  :error="$v.info.model_year.$error"
                  :isClear="false"
                  helperText="Please select model year"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                  :optional="isNZ"
                />
              </b-col>
              <b-col v-if="!isNZ" cols="12" md="6" class="px-3">
                <custom-clear-select
                  id="engine-type"
                  label="Engine type"
                  name="engineType"
                  v-model="info.engineType"
                  :options="engineTypes"
                  :isFocus="curStepInput === 13"
                  :error="$v.info.engineType.$error"
                  :isClear="this.isClearOptions.isClearEngine"
                  :mapper="redbookDataMapper"
                  helperText="Please select engine type"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col v-if="!isNZ" cols="12" md="6" class="px-3">
                <custom-clear-select
                  id="engine-size"
                  label="Engine size"
                  name="engineSize"
                  v-model="info.engineSize"
                  :options="engineSizes"
                  :isFocus="curStepInput === 14"
                  :error="$v.info.engineSize.$error"
                  :isClear="this.isClearOptions.isClearEngineSize"
                  helperText="Please select engine size"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col v-if="!isNZ" cols="12" md="6" class="px-3">
                <custom-clear-select
                  id="number-of-seats"
                  label="Number of seats"
                  name="numberOfSeat"
                  v-model="info.numberOfSeat"
                  :options="numberOfSeats"
                  :isFocus="curStepInput === 15"
                  :error="$v.info.numberOfSeat.$error"
                  :isClear="this.isClearOptions.isClearNumberOfSeat"
                  helperText="Please select number of seats"
                  @setNextInput="setNextInputNumber"
                  :selectDisabled="checkReadonly"
                />
              </b-col>
              <b-col cols="12" md="6" class="px-3">
                <custom-date-picker
                  label="Build date"
                  v-model="info.buildDate"
                  name="buildDate"
                  @setNextInput="setNextInputNumber"
                  placeholder="MM/YY"
                  format="MM/YY"
                  :error="buildDateValidate !== ''"
                  :helperText="buildDateValidate"
                  :selectDisabled="checkReadonly"
                  :isMobile="isMobile"
                />
                <a
                  class="position-absolute build-date-text"
                  target="_blank"
                  :href="myCarLinks.buildDate"
                  >Where can I find this?</a
                >
              </b-col>
            </b-row>
            <QuestionAndAnswer :questions="questions" />
          </div>
          <div style="height: 50px;"></div>
          <btn-group
            @onSubmit="next"
            @onCancel="goBack"
            @onSave="openDialog"
            submitText="Confirm"
            :disableSubmit="!isValid && !checkReadonly"
            :isMobile="isMobile"
          />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { 
  FETCH_DETAIL, 
  SET_INFO, 
  FETCH_INIT,
  FETCH_MODELS,
  FETCH_YEARS,
  FETCH_BADGES,
  SET_PREV_LENGTH,
  SET_REGO_SEARCH_FLAG,
  SET_REDBOOK_FLAG,
  SET_OTHER_OPTIONS,
} from "@/store/info/actions";
import {
  CLEAR_INFO_EXTEND,
  UPDATE_REGO_AND_STATE,
  SET_CAR_INFO,
  REGO_RE_LOOKUP,
  UPDATE_DATA,
  REGO_ADDITIONAL_LOOKUP,
} from "@/store/authenticate-seller/actions";
import mixin from "@/mixins/common";
import { required } from "vuelidate/lib/validators";
import customerDetailService from "@/services/customer-detail";
import LeadUpdateService from "@/services/lead-update";
import topPageService from "@/services/top-page";
import regex, { logError } from "../../helper/utils";
import { browserList } from "@/helper/constant";
import { blankOptionList } from "@/helper/constant";

export default {
  name: "Index",
  mixins: [mixin],
  components: {
    CustomSelect: () => import("../../components/common/CustomSelect"),
    CustomClearSelect: () => import("../../components/common/CustomClearSelect"),
    BtnGroup: () => import("../../components/common/BtnGroup.vue"),
    CustomAutocompleteInput: () => import("../../components/common/CustomAutocompleteInput.vue"),
    QuestionAndAnswer: () => import("../../components/common/QuestionAndAnswer.vue"),
    CustomDialog: () => import("../../components/common/CustomDialog"),
    ListedCarMessage: () => import("../../components/common/ListedCarMessage.vue"),
    CustomDatePicker: () => import("../../components/common/CustomDatePicker.vue"),
    RegoPopup: () => import("../../components/vehicle/RegoPopup.vue"),
  },
  data() {
    return {
      submitted: false,
      curStepInput: 1,
      inputStep: [
        {
          step: 1,
          key: "location",
        },
        {
          step: 2,
          key: "make",
        },
        {
          step: 3,
          key: "model",
        },
        {
          step: 4,
          key: "color",
        },
        {
          step: 5,
          key: "year",
        },
        {
          step: 6,
          key: "fuelType",
        },
        {
          step: 7,
          key: "drive",
        },
        {
          step: 8,
          key: "transmission",
        },
        {
          step: 9,
          key: "bodyType",
        },
        {
          step: 10,
          key: "badgeEdition",
        },
        {
          step: 11,
          key: "series",
        },
        {
          step: 12,
          key: "model_year",
        },
        {
          step: 13,
          key: "engineType",
        },
        {
          step: 13,
          key: "engineSize",
        },
        {
          step: 13,
          key: "numberOfSeat",
        },
        {
          step: 14,
          key: "",
        },
      ],
      beforeUpdateBadges: this.$store.getters.badges,
      currSelfServiceConfigList: [
        "make",
        "model",
        "year",
        "badge",
        "series",
        "color",
        "bodyType",
        "fuelType",
        "model_year",
        "transmission",
        "engineType",
      ],
      regoInfo: this.$store.getters.regoSearch,
      redbookDataMapper: null,
      readOnlyMode: true,
      customerName: this.$store.getters.customerName,
      questions: [
        {
          id: "question-1",
          question: `Why can't I edit some details on this page?`,
          answer: `Some details on this page are not editable. If you need to edit it, please <u id="jiraIssueLink" style="cursor: pointer;">contact us</u>.`,
          isActive: false,
          reportLink: true,
        },
      ],
      isMobile: true,
      isDialogOpen: false,
      isCurrPageChanges: false,
      initUpdateVariant: true,
      isLoadingApi: false,
      isSpecialDeviceBrowser: false,
      regoUpdating: false,
      initUpdateEngineSize: true,
      initUpdateNumberOfSeat: true,
      callCountEngineSize: 0,
      callCountNumberOfSeat: 0,
    };
  },
  computed: {
    ...mapState({
      info: (state) => state.authenticateSeller.carInfo,
      makes: (state) => state.info.makes,
      models: (state) => state.info.models,
      years: (state) => state.info.years,
      colors: (state) => state.info.colors,
      badges: (state) => state.info.badges,
      editions: (state) => state.info.editions,
      drives: (state) => state.info.drives,
      transmissions: (state) => state.info.transmissions,
      shifts: (state) => state.info.shifts,
      steps: (state) => state.common.steps,
      isShowRegoPopup: (state) => state.common.isShowRegoPopup,
      fuelTypes: (state) => state.info.fuelTypes,
      bodyTypes: (state) => state.info.bodyTypes,
      series: (state) => state.info.series,
      engineTypes: (state) => state.info.engineTypes,
      engineSizes: (state) => state.info.engineSizes,
      numberOfSeats: (state) => state.info.numberOfSeats,
      selfServiceConfig: (state) => state.selfServiceQualifier.config,
      isClearOptions: (state) => state.info.isClearOptions,
      authenticateData: (state) => state.authenticateSeller,
      uploadImages: (state) => state.photo.uploadImage,
      modelYears: (state) => state.info.modelYears,
      badgeEditions: (state) => state.info.badgeEditions,
      isIdle: (state) => state.common.isIdle,
      myCarLinks: (state) => state.commonVariables.myCarLinks,
      isCalledRedbook: (state) => state.info.isCalledRedbook,
    }),
    states: function () {
      return ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
    },
    isValid: function () {
      return !this.$v.info.$invalid;
    },
    badgeRequestValue: function() {
      return !blankOptionList.includes(this.info.badge) ? this.info.badge : ''
    },
    currSelfServiceConfig: function() {
      return Object.fromEntries(
        Object
          .entries(this.selfServiceConfig)
          .filter(([key]) => this.currSelfServiceConfigList.includes(key))
      );
    },
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 900px)");
    },
    buildDateValidate: function() {
      if (
        this.info.buildDate !== "" &&
        !regex("((0[1-9])|(1[0-2]))/[0-9]{2}", this.info.buildDate)
      ) {
        return "Invalid date format, must be MM/YY";
      }
      return "";
    },
    checkReadonly: function() {
      return this.authenticateData.listed || this.authenticateData.readonlyMode;
    },
    isNZ: function() {
      return parseInt(this.authenticateData.locid) === 2;
    },
  },
  methods: {
    next: async function () {
      if (!this.checkReadonly) {
        this.$v.info.$touch();
      }
      if (this.isValid || this.checkReadonly) {
        await this.$store.dispatch(SET_CAR_INFO, {
          ...this.info,
        });
        this.$router.push({
          name: "navigation",
          query: this.$route.query
        });
      }
    },
    goBack: function() {
      this.$router.push({ name: "navigation", query: this.$route.query });
    },
    getData: async function () {
      await this.$store.dispatch(FETCH_INIT, {
        model: this.info.model !== 'Other' ? this.info.model : '',
        make: this.info.make,
        year: this.info.model !== 'Other' ? this.info.year : '',
        locid: parseInt(this.authenticateData.locid),
      });
    },
    changeData: function () {
      this.info.badge = "";
    },
    setNextInputNumber() {
      for (const item of this.inputStep) {
        if (!this.info[item.key] || item.key === "") {
          this.curStepInput = item.step;
          break;
        }
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode >= 48 &&
        charCode <= 57 &&
        !(charCode === 48 && this.info.kms === "")
      ) {
        return true;
      } else {
        evt.preventDefault();
        return false;
      }
    },
    removeFirstZero() {
      if (/^0/.test(this.info.kms)) {
        this.info.kms = this.info.kms.replace(/^0+/, "");
      }
    },
    fetchDetailRequestValues(name, val) {
      let allRequestValues = {};
      if (this.initUpdateVariant) {
        this.initUpdateVariant = false;
        allRequestValues = {
          ...(this.info.make ? { make: this.info.make } : {}),
          ...(this.info.model && this.info.model !== "Other" ? { model: this.info.model } : {}),
          ...(this.info.year ? { year: this.info.year } : {}),
          ...(this.info.badge && !blankOptionList.includes(this.info.badge) ? { badge: this.info.badge } : {}),
          ...(this.info.edition && !blankOptionList.includes(this.info.edition) ? { edition: this.info.edition } : {}),
        }
      } else {
        allRequestValues = {
          ...(this.info.make ? { make: this.info.make } : {}),
          ...(this.info.model && this.info.model !== "Other" ? { model: this.info.model } : {}),
          ...(this.info.year ? { year: this.info.year } : {}),
          ...(this.info.badge && !blankOptionList.includes(this.info.badge) ? { badge: this.info.badge } : {}),
          ...(this.info.edition && !blankOptionList.includes(this.info.edition) ? { edition: this.info.edition } : {}),
          ...(this.info.bodyType ? { bodyType: this.info.bodyType } : {}),
          ...(this.info.fuelType ? { fuelType: this.info.fuelType } : {}),
          ...(this.info.transmission ? { transmission: this.info.transmission } : {}),
          ...(this.info.series && !blankOptionList.includes(this.info.series) ? { series: this.info.series } : {}),
          ...(this.info.engineType && this.info.engineType !== 'Not sure' ? { engineType: this.info.engineType } : {}),
          ...(this.info.drive ? { drive: this.info.drive } : {}),
          ...(this.info.engineSize ? { engineSize: this.info.engineSize } : {}),
          ...(this.info.numberOfSeat ? { numberOfSeat: this.info.numberOfSeat } : {}),
        }
      }
      return {
        ...allRequestValues,
        [name]: val && val !== 'Not sure' ? val : ''
      }
    },
    getRedbookDataMapper() {
      customerDetailService
        .getRedbookDataMapper()
        .then(({ data }) => {
          this.redbookDataMapper = data;
          this.$store.dispatch(SET_INFO, {
            ...this.info,
            redbookDataMapper: data,
          });
        });
    },
    active(num) {
      this.questions[num].isActive = !this.questions[num].isActive;
    },
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    handleBeforeUnload(event) {
      if (!this.isIdle) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    async saveOrUpdateTempData() {
      this.$store.dispatch(UPDATE_DATA, this.authenticateData);
    },
    skipRego() {
      this.$store.dispatch('updateIsShowRegoPopup', false);
    },
    async handleRegoSearch(regoNumber, state, leadSource) {
      if (regoNumber !== 'TEMPREGO' && regoNumber !== '') {
        try {
          this.$store.dispatch(SET_REGO_SEARCH_FLAG, true);
          const seachResult = await topPageService.searchRego({
            regoNumber,
            state,
            leadSource,
            locid: parseInt(this.authenticateData.locid),
          });
          if (seachResult.status === 201) {
            this.regoUpdating = true;
            this.$store.dispatch(REGO_RE_LOOKUP, seachResult.data);
            LeadUpdateService.updateRego({
              regoNumber: regoNumber,
              state: state ? state : null,
              appraisalId: this.authenticateData.appraisalId,
              leadId: this.$store.getters.leadId,
              customerName: this.customerName,
              customerEmail: this.authenticateData.email,
              locid: parseInt(this.authenticateData.locid),
            })
          }
        } catch (error) {
          if (error.response.status !== 404) {
            logError(`Update rego error: ${error}`);
          }
        }
      }
    },
    async updateRego(regoPopupInfo) {
      this.isLoadingApi = true;
      try {
        this.handleRegoSearch(regoPopupInfo.regoNumber, regoPopupInfo.state, this.authenticateData.leadSource)
          .then(() => {
            this.$store.dispatch(UPDATE_REGO_AND_STATE, regoPopupInfo);
            this.isLoadingApi = false;
            this.$store.dispatch('updateIsShowRegoPopup', false);
          })
      } catch (error) {
        console.log(error);
        this.isLoadingApi = false;
      }
    },
    async handleRegoLookup(regoNumber, state, leadSource) {
      try {
        const seachResult = await topPageService.searchRego({
          regoNumber,
          state,
          leadSource,
        });
        if (seachResult.status === 201) {
          this.$store.dispatch(REGO_ADDITIONAL_LOOKUP, seachResult.data);
        }
      } catch (error) {
        if (error.response.status !== 404) {
          logError(`Rego search error: ${error}`);
        }
      }
    },
  },
  validations: {
    info: {
      make: {
        required,
      },
      model: {
        required,
      },
      year: {
        required,
      },
      color: {
        required,
      },
      location: {
        required: function() {
          return this.isNZ || this.info.location !== '';
        },
      },
      drive: {
        required: function() {
          return this.drives.length < 1 || this.info.drive !== '';
        },
      },
      transmission: {
        required: function() {
          return this.transmissions.length < 1 || this.info.transmission !== '';
        },
      },
      badgeEdition: {
        required: function () {
          return (
            this.badgeEditions.length < 1 || this.info.badgeEdition !== "" || this.isNZ
          );
        },
      },
      fuelType: {
        required: function() {
          return this.fuelTypes.length < 1 || this.info.fuelType !== '';
        },
      },
      series: {
        required: function() {
          return this.series.length < 1 || this.info.series !== '' || this.isNZ;
        },
      },
      model_year: {
        required: function () {
          return (
            this.modelYears.length < 1 || this.info.model_year !== "" || this.isNZ
          );
        },
      },
      engineType: {
        required: function() {
          return this.engineTypes.length < 1 || this.info.engineType !== '' || this.isNZ;
        },
      },
      bodyType: {
        required: function() {
          return this.bodyTypes.length < 1 || this.info.bodyType !== '';
        },
      },
      engineSize: {
        required: function() {
          return this.engineSizes.length < 1 || this.info.engineSize !== '' || this.isNZ;
        },
      },
      numberOfSeat: {
        required: function() {
          return this.numberOfSeats.length < 1 || this.info.numberOfSeat !== '' || this.isNZ;
        },
      },
    },
  },
  watch: {
    "info.make": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (!this.regoUpdating) {
          this.$store.dispatch(FETCH_MODELS, {
            make: val,
            locid: parseInt(this.authenticateData.locid),
          });
          this.curStepInput = 2;
          this.onChange = true;
        }
      }
    },
    "info.model": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (!this.regoUpdating) {
          if (val !== 'Other') {
            this.$store.dispatch(CLEAR_INFO_EXTEND);
            this.$store.dispatch(FETCH_YEARS, {
              make: this.info.make,
              model: val,
              locid: parseInt(this.authenticateData.locid),
            });
            this.curStepInput = 3;
            this.onChange = true;
          } else {
            this.$store.dispatch(CLEAR_INFO_EXTEND);
            this.$store.dispatch(SET_OTHER_OPTIONS, {
              make: this.info.make,
              model: '',
              locid: parseInt(this.authenticateData.locid),
            });
          }
        }
      }
    },
    "info.year": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (!this.regoUpdating && this.info.model !== 'Other') {
          this.$store.dispatch(CLEAR_INFO_EXTEND);
          this.$store.dispatch(FETCH_BADGES, {
            make: this.info.make,
            model: this.info.model,
            year: val,
            locid: parseInt(this.authenticateData.locid),
          });
          this.curStepInput = 5;
        }
      }
    },
    "info.badgeEdition": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (
          val.split('|').some(value => !blankOptionList.includes(value)) &&
          !this.regoUpdating &&
          this.info.model !== 'Other'
        ) {
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthBadgeEdition', stateName: 'badgeEditions' });
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthBody', stateName: 'bodyTypes' }); 
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthFuel', stateName: 'fuelTypes' });
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthDrive', stateName: 'drives' });
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthTrans', stateName: 'transmissions' });
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthSeries', stateName: 'series' });
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthEngine', stateName: 'engineTypes' });
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthEngineSize', stateName: 'engineSizes' });
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthNumberOfSeat', stateName: 'numberOfSeats' });
          const splittedVal = val.split("|");
          this.$store.dispatch(FETCH_DETAIL, {
            ...this.fetchDetailRequestValues("badge", splittedVal[0]),
            edition: splittedVal[1],
            locid: parseInt(this.authenticateData.locid),
          });
        } else if (val && val.includes('Not sure')) {
          this.info.relevantRBDesc = "";
        }
        this.curStepInput = 6;
      }
    },
    "info.bodyType": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (!this.regoUpdating && this.info.model !== 'Other') {
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthBody', stateName: 'bodyTypes' }); 
          this.$store.dispatch(FETCH_DETAIL, {
            ...this.fetchDetailRequestValues('bodyType', val),
            locid: parseInt(this.authenticateData.locid),
          });
          this.curStepInput = 7;
        }
      }
    },
    "info.fuelType": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (!this.regoUpdating && !this.isNZ && this.info.model !== 'Other') {
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthFuel', stateName: 'fuelTypes' });
          this.$store.dispatch(FETCH_DETAIL, {
            ...this.fetchDetailRequestValues('fuelType', val),
            locid: parseInt(this.authenticateData.locid),
          });
          this.curStepInput = 8;
        }
      }
    },
    "info.drive": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (!this.regoUpdating && this.info.model !== 'Other') {
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthDrive', stateName: 'drives' });
          this.$store.dispatch(FETCH_DETAIL, {
            ...this.fetchDetailRequestValues('drive', val),
            locid: parseInt(this.authenticateData.locid),
          });
          this.curStepInput = 9;
        }
      }
    },
    "info.transmission": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (!this.regoUpdating && this.info.model !== 'Other') {
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthTrans', stateName: 'transmissions' });
          this.$store.dispatch(FETCH_DETAIL, {
            ...this.fetchDetailRequestValues('transmission', val),
            locid: parseInt(this.authenticateData.locid),
          });
          this.curStepInput = 10;
        }
      }
    },
    "info.series": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (
          !blankOptionList.includes(val) &&
          !this.regoUpdating &&
          !this.isNZ &&
          this.info.model !== 'Other'
        ) {
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthSeries', stateName: 'series' });
          this.$store.dispatch(FETCH_DETAIL, {
            ...this.fetchDetailRequestValues('series', val),
            locid: parseInt(this.authenticateData.locid),
          });
        }
        this.curStepInput = 11;
      }
    },
    "info.model_year": function(val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
      }
      this.curStepInput = 12;
    },
    "info.engineType": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (
          val !== 'Not sure' &&
          !this.regoUpdating &&
          !this.isNZ &&
          this.info.model !== 'Other'
        ) {
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthEngine', stateName: 'engineTypes' });
          this.$store.dispatch(FETCH_DETAIL, {
            ...this.fetchDetailRequestValues('engineType', val),
            locid: parseInt(this.authenticateData.locid),
          });
        }
        this.curStepInput = 13;
      }
    },
    "info.buildDate": function (val) {
      if (val) {
        this.$store.dispatch('setOnEditPage', true);
        if (!this.regoUpdating) {
          this.curStepInput = 14;
          this.isCurrPageChanges = true;
        }
      }
    },
    "regoUpdating": function(val) {
      if (val) {
        this.getData().then(() => {
          this.regoUpdating = false;
        })
      }
    },
    "info.engineSize": function (val, prevVal) {
      if (!this.isNZ) {
        this.callCountEngineSize++;
        if (this.isCalledRedbook && this.callCountEngineSize === 1 && prevVal !== '') {
          this.initUpdateEngineSize = true;
        } else {
          this.initUpdateEngineSize = false;
        }
        if(
          val &&
          val !== 'Not sure' &&
          !this.regoUpdating &&
          !this.initUpdateEngineSize &&
          this.info.model !== 'Other'
        ) {
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthEngineSize', stateName: 'engineSizes' });
          this.$store.dispatch(FETCH_DETAIL, {
            ...this.fetchDetailRequestValues('engineSize', val),
            locid: parseInt(this.authenticateData.locid),
          });
        }
      }
      this.curStepInput = 14;
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.numberOfSeat": function (val, prevVal) {
      if (!this.isNZ) {
        this.callCountNumberOfSeat++;
        if (this.isCalledRedbook && this.callCountNumberOfSeat === 1 && !isNaN(prevVal)) {
          this.initUpdateNumberOfSeat = true;
        } else {
          this.initUpdateNumberOfSeat = false;
        }
        if(
          val &&
          val !== 'Not sure' &&
          !this.regoUpdating &&
          !this.initUpdateNumberOfSeat &&
          this.info.model !== 'Other'
        ) {
          this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthNumberOfSeat', stateName: 'numberOfSeats' });
          this.$store.dispatch(FETCH_DETAIL, {
            ...this.fetchDetailRequestValues('numberOfSeat', val),
            locid: parseInt(this.authenticateData.locid),
          });
        }
      }
      this.curStepInput = 15;
      this.$store.dispatch('setOnEditPage', true);
    },
    "info.color": function() {
      this.$store.dispatch('setOnEditPage', true);
      console.log('color')
    },
    curStepInput: function () {
      let progress = 0;
      for (const item of this.inputStep) {
        const emptyApproved = item.key === 'series' || item.key === 'badgeEdition';
        if ((this.info[item.key] || emptyApproved) && item.key !== "") {
          progress = progress + (10 / (this.inputStep.length - 1));
        }
      }
      // this.$store.dispatch("setProgress", Math.floor(progress) * 10);
    },
  },
  created: function () {
    this.getData();
    // this.$store.dispatch(GET_CONFIG_INTERVAL);  // get config with interval
    this.getRedbookDataMapper();
  },
  mounted() {
    if (!this.isNZ && this.authenticateData.rego !== 'TEMPREGO' && this.authenticateData.rego !== '' && !this.isCalledRedbook &&
    this.authenticateData.carInfo.engineSize === '' && this.authenticateData.carInfo.numberOfSeat === '') {
      this.handleRegoLookup(this.authenticateData.rego, this.authenticateData.carInfo.location, this.authenticateData.leadSource);
      this.$store.dispatch(SET_REDBOOK_FLAG, true);
    }
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
    this.isMobile = !this.mobileMediaQuery.matches;

    if (this.isMobile) {
      const userAgent = navigator.userAgent || window.opera; // Check user use in-app facebook or instagram browser android so can only upload
      const isiOSChromeBrowser = (userAgent.includes('iPhone') || userAgent.includes('iPad')) && userAgent.includes(browserList);
      
      if (isiOSChromeBrowser) this.isSpecialDeviceBrowser = true;
    }

    // Check is close tab
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    this.$store.dispatch('setOnEditPage', false);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
};
</script>

<style lang="scss">

.vehicle-info-content {
  margin: 0 auto;
  text-align: left;
  padding: 0 10px;
  // min-height: calc(100vh - 179px);
  // overflow: auto;
  .listed-message-banner {
    margin: 20px 8px 10px 8px;
  }
  .card {
    background-color: transparent;
    border: none !important;
    padding-top: 0 !important;
    .card-body {
      padding: 0;
    }
  }
  .btn-group-wrapper {
    .action-btn {
      border: 1px solid var(--primary-color);
    }
  }
}

.welcome-message {
  font-size: 20px;
  line-height: 28px;
  color: var(--primary-color);
  margin-bottom: 0 !important;
  p {
    margin-bottom: 0.5rem !important;
  }
}

.content-title {
  color: var(--primary-color);
}

.auto-info {
  background-color: #f2f2f2;
  border-color: #b3b3b3;
  margin-left: 10px;
  margin-right: 0;
}
.next-input {
  color: #383C3F;
  background-color: #fff;
  border-color: var(--primary-color);
  outline: 0;
}

.build-date-text {
  color: var(--primary-color);
  text-decoration: underline;
  margin-right: 16px;
  right: 0px !important;
  margin-top: -10px;
  &:hover {
    color: var(--primary-color);
  }
}

@media screen and(min-width: 768px) {
  .card {
    padding: 0 48px 24px !important;
  }

  .condition-title {
    font-size: 0.9rem !important;
  }
}

@media screen and(min-width: 900px) {
  .vehicle-info-content .card {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and(min-width: 768px) and(max-width: 900px) {
  .vehicle-info-content {
    .listed-message-banner {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
}
@media screen and(min-width: 900px) and(max-width: 1200px) {
  .vehicle-info-content {
    .listed-message-banner {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media screen and(min-width: 1200px) {
  .vehicle-info-content {
    .listed-message-banner {
      margin-left: 70px;
      margin-right: 70px;
    }
    .card {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    .btn-group-wrapper {
      padding-left: 100px;
      padding-right: 100px;
    }
    .base-width {
      max-width: 955px;
    }
  }
}
</style>
